
// @ts-nocheck


export const localeCodes =  [
  "en",
  "sk"
]

export const localeLoaders = {
  "en": [{ key: "../src/lang/en.json", load: () => import("../src/lang/en.json" /* webpackChunkName: "locale__app_src_lang_en_json" */), cache: true }],
  "sk": [{ key: "../src/lang/sk.json", load: () => import("../src/lang/sk.json" /* webpackChunkName: "locale__app_src_lang_sk_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../../common_fe/i18n.config.ts?hash=5926adf4&config=1" /* webpackChunkName: "__i18n_config_ts_5926adf4" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "format": "en-GB",
      "language": "en",
      "files": [
        "/app/src/lang/en.json"
      ]
    },
    {
      "code": "sk",
      "name": "Slovensky",
      "format": "sk-SK",
      "language": "sk",
      "files": [
        "/app/src/lang/sk.json"
      ]
    }
  ],
  "defaultLocale": "sk",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "./lang",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "format": "en-GB",
    "language": "en",
    "files": [
      {
        "path": "/app/src/lang/en.json"
      }
    ]
  },
  {
    "code": "sk",
    "name": "Slovensky",
    "format": "sk-SK",
    "language": "sk",
    "files": [
      {
        "path": "/app/src/lang/sk.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
