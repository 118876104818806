import { default as _sso_45finishHdUl2pHrIQMeta } from "/app/src/pages/[partner]/_sso-finish.vue?macro=true";
import { default as _ssoiWyt3lfnUkMeta } from "/app/src/pages/[partner]/_sso.vue?macro=true";
import { default as bonuses3TI8GYjEz1Meta } from "/app/src/pages/[partner]/bonuses.vue?macro=true";
import { default as contactuBknK7dohlMeta } from "/app/src/pages/[partner]/contact.vue?macro=true";
import { default as indexOnh2K3c4arMeta } from "/app/src/pages/[partner]/index.vue?macro=true";
import { default as samsung_45privacy_45policyJ8mXrROdmVMeta } from "/app/src/pages/[partner]/samsung-privacy-policy.vue?macro=true";
import { default as _91partner_93O9CCOJuUNYMeta } from "/app/src/pages/[partner].vue?macro=true";
import { default as ambassadorV2ToOtuAn8Meta } from "/app/src/pages/ambassador.vue?macro=true";
import { default as index6lio2UKUC0Meta } from "/app/src/pages/bank-account-verification/index.vue?macro=true";
import { default as bonusesej8Y1opNf2Meta } from "/app/src/pages/bonuses.vue?macro=true";
import { default as _91id_939C9jCBsmbKMeta } from "/app/src/pages/buyout-detail/[id].vue?macro=true";
import { default as _91_91quality_93_93hOHeUd3myzMeta } from "/app/src/pages/buyout/[[type]]/[[manufacturer]]/[[meta_master]]/[[variant]]/[[color]]/[[quality]].vue?macro=true";
import { default as _91token_93ektCOnLsFyMeta } from "/app/src/pages/change-password/[uid]/[token].vue?macro=true";
import { default as indexn8gFv0ycJqMeta } from "/app/src/pages/change-password/index.vue?macro=true";
import { default as commission_45systemqpTTmu12tfMeta } from "/app/src/pages/commission-system.vue?macro=true";
import { default as contactmqHVTjI8koMeta } from "/app/src/pages/contact.vue?macro=true";
import { default as cookiesljJJ99na9gMeta } from "/app/src/pages/cookies.vue?macro=true";
import { default as dpd_45mapCU2rs7HyqhMeta } from "/app/src/pages/dpd-map.vue?macro=true";
import { default as _91token_93xFfebiZdaKMeta } from "/app/src/pages/forgotten-password/[uid]/[token].vue?macro=true";
import { default as index3sWtABJ4MtMeta } from "/app/src/pages/forgotten-password/index.vue?macro=true";
import { default as import_45ambassadorspgVJXOWr3dMeta } from "/app/src/pages/import-ambassadors.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as _91id_93MoWQUnXsdLMeta } from "/app/src/pages/invite-action/[id].vue?macro=true";
import { default as login3hUYZUGxmUMeta } from "/app/src/pages/login.vue?macro=true";
import { default as _91id_93jtkvIme7zdMeta } from "/app/src/pages/paper-contract-sign/[id].vue?macro=true";
import { default as payout_45instructionmxUQmQR50dMeta } from "/app/src/pages/payout-instruction.vue?macro=true";
import { default as premiumBHgECUpnrUMeta } from "/app/src/pages/premium.vue?macro=true";
import { default as privacy_45policyjUOcienEDCMeta } from "/app/src/pages/privacy-policy.vue?macro=true";
import { default as ambassadorsf8qZ2JJZhSMeta } from "/app/src/pages/profile-premium/ambassadors.vue?macro=true";
import { default as apiQqhvggB0uWMeta } from "/app/src/pages/profile-premium/api.vue?macro=true";
import { default as company_45datayn4DbaCakSMeta } from "/app/src/pages/profile-premium/company-data.vue?macro=true";
import { default as dashboardT4RY18FzjlMeta } from "/app/src/pages/profile-premium/dashboard.vue?macro=true";
import { default as educationEs5PLyFF4vMeta } from "/app/src/pages/profile-premium/education.vue?macro=true";
import { default as exportsoj7hKc9fMrMeta } from "/app/src/pages/profile-premium/exports.vue?macro=true";
import { default as indexhcifUt38GMMeta } from "/app/src/pages/profile-premium/index.vue?macro=true";
import { default as notificationsEwzLQDx5lXMeta } from "/app/src/pages/profile-premium/notifications.vue?macro=true";
import { default as profile_45premium200sa3HzYZMeta } from "/app/src/pages/profile-premium.vue?macro=true";
import { default as bonusesNwXnstYbQHMeta } from "/app/src/pages/profile/bonuses.vue?macro=true";
import { default as dashboardJRLL2JnLGsMeta } from "/app/src/pages/profile/dashboard.vue?macro=true";
import { default as educationFTygOngblCMeta } from "/app/src/pages/profile/education.vue?macro=true";
import { default as indexSJNeMkG0qzMeta } from "/app/src/pages/profile/index.vue?macro=true";
import { default as messagesii0Fq7i6l6Meta } from "/app/src/pages/profile/messages.vue?macro=true";
import { default as my_45buyoutsnAdRS7UEZiMeta } from "/app/src/pages/profile/my-buyouts.vue?macro=true";
import { default as notificationscoYkBtAkcOMeta } from "/app/src/pages/profile/notifications.vue?macro=true";
import { default as personal_45infoV0X93sJxn0Meta } from "/app/src/pages/profile/personal-info.vue?macro=true";
import { default as rewardsAJtPX8JePWMeta } from "/app/src/pages/profile/rewards.vue?macro=true";
import { default as tariff_45listBPA80H7A3hMeta } from "/app/src/pages/profile/tariff-list.vue?macro=true";
import { default as profileTx35vCKuH1Meta } from "/app/src/pages/profile.vue?macro=true";
import { default as questions3T0iefRra1Meta } from "/app/src/pages/questions.vue?macro=true";
import { default as ambassadorfAMyKOcGo8Meta } from "/app/src/pages/register/ambassador.vue?macro=true";
import { default as partnerf6O1AltUwjMeta } from "/app/src/pages/register/partner.vue?macro=true";
import { default as _91token_93Y0kYH2hlebMeta } from "/app/src/pages/set-new-password/[uid]/[token].vue?macro=true";
import { default as _91id_93bW8M5cw9eRMeta } from "/app/src/pages/sms-contract-sign/[id].vue?macro=true";
import { default as terms_45and_45conditionsg5tk8tJRWzMeta } from "/app/src/pages/terms-and-conditions.vue?macro=true";
import { default as sso_45finishktFnF3flDKMeta } from "/app/src/pages/vodafone/sso-finish.vue?macro=true";
import { default as ssoVB0kdzIVIqMeta } from "/app/src/pages/vodafone/sso.vue?macro=true";
export default [
  {
    name: _91partner_93O9CCOJuUNYMeta?.name,
    path: "/en/:partner()",
    meta: _91partner_93O9CCOJuUNYMeta || {},
    component: () => import("/app/src/pages/[partner].vue").then(m => m.default || m),
    children: [
  {
    name: "partner-_sso-finish___en",
    path: "_sso-finish",
    meta: _sso_45finishHdUl2pHrIQMeta || {},
    component: () => import("/app/src/pages/[partner]/_sso-finish.vue").then(m => m.default || m)
  },
  {
    name: "partner-_sso___en",
    path: "_sso",
    meta: _ssoiWyt3lfnUkMeta || {},
    component: () => import("/app/src/pages/[partner]/_sso.vue").then(m => m.default || m)
  },
  {
    name: "partner-bonuses___en",
    path: "bonuses",
    meta: bonuses3TI8GYjEz1Meta || {},
    component: () => import("/app/src/pages/[partner]/bonuses.vue").then(m => m.default || m)
  },
  {
    name: "partner-contact___en",
    path: "contact",
    meta: contactuBknK7dohlMeta || {},
    component: () => import("/app/src/pages/[partner]/contact.vue").then(m => m.default || m)
  },
  {
    name: "partner___en",
    path: "",
    meta: indexOnh2K3c4arMeta || {},
    component: () => import("/app/src/pages/[partner]/index.vue").then(m => m.default || m)
  },
  {
    name: "partner-samsung-privacy-policy___en",
    path: "samsung-privacy-policy",
    meta: samsung_45privacy_45policyJ8mXrROdmVMeta || {},
    component: () => import("/app/src/pages/[partner]/samsung-privacy-policy.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91partner_93O9CCOJuUNYMeta?.name,
    path: "/:partner()",
    meta: _91partner_93O9CCOJuUNYMeta || {},
    component: () => import("/app/src/pages/[partner].vue").then(m => m.default || m),
    children: [
  {
    name: "partner-_sso-finish___sk",
    path: "_sso-finish",
    meta: _sso_45finishHdUl2pHrIQMeta || {},
    component: () => import("/app/src/pages/[partner]/_sso-finish.vue").then(m => m.default || m)
  },
  {
    name: "partner-_sso___sk",
    path: "_sso",
    meta: _ssoiWyt3lfnUkMeta || {},
    component: () => import("/app/src/pages/[partner]/_sso.vue").then(m => m.default || m)
  },
  {
    name: "partner-bonuses___sk",
    path: "bonusy",
    meta: bonuses3TI8GYjEz1Meta || {},
    component: () => import("/app/src/pages/[partner]/bonuses.vue").then(m => m.default || m)
  },
  {
    name: "partner-contact___sk",
    path: "kontakt",
    meta: contactuBknK7dohlMeta || {},
    component: () => import("/app/src/pages/[partner]/contact.vue").then(m => m.default || m)
  },
  {
    name: "partner___sk",
    path: "",
    meta: indexOnh2K3c4arMeta || {},
    component: () => import("/app/src/pages/[partner]/index.vue").then(m => m.default || m)
  },
  {
    name: "partner-samsung-privacy-policy___sk",
    path: "informace-o-zpracovani-osobnich-udaju-samsung",
    meta: samsung_45privacy_45policyJ8mXrROdmVMeta || {},
    component: () => import("/app/src/pages/[partner]/samsung-privacy-policy.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "ambassador___en",
    path: "/en/ambassador",
    meta: ambassadorV2ToOtuAn8Meta || {},
    component: () => import("/app/src/pages/ambassador.vue").then(m => m.default || m)
  },
  {
    name: "ambassador___sk",
    path: "/ambasador",
    meta: ambassadorV2ToOtuAn8Meta || {},
    component: () => import("/app/src/pages/ambassador.vue").then(m => m.default || m)
  },
  {
    name: "bank-account-verification___en",
    path: "/en/bank-account-verification",
    component: () => import("/app/src/pages/bank-account-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "bank-account-verification___sk",
    path: "/overenie-bankoveho-uctu",
    component: () => import("/app/src/pages/bank-account-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "bonuses___en",
    path: "/en/bonuses",
    meta: bonusesej8Y1opNf2Meta || {},
    component: () => import("/app/src/pages/bonuses.vue").then(m => m.default || m)
  },
  {
    name: "bonuses___sk",
    path: "/bonusy",
    meta: bonusesej8Y1opNf2Meta || {},
    component: () => import("/app/src/pages/bonuses.vue").then(m => m.default || m)
  },
  {
    name: "buyout-detail-id___en",
    path: "/en/buyout-detail/:id()",
    meta: _91id_939C9jCBsmbKMeta || {},
    component: () => import("/app/src/pages/buyout-detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "buyout-detail-id___sk",
    path: "/detail-vykupu/:id()",
    meta: _91id_939C9jCBsmbKMeta || {},
    component: () => import("/app/src/pages/buyout-detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "buyout-type-manufacturer-meta_master-variant-color-quality___en",
    path: "/en/buyout/:type?/:manufacturer?/:meta_master?/:variant?/:color?/:quality?",
    meta: _91_91quality_93_93hOHeUd3myzMeta || {},
    component: () => import("/app/src/pages/buyout/[[type]]/[[manufacturer]]/[[meta_master]]/[[variant]]/[[color]]/[[quality]].vue").then(m => m.default || m)
  },
  {
    name: "buyout-type-manufacturer-meta_master-variant-color-quality___sk",
    path: "/vykup/:type?/:manufacturer?/:meta_master?/:variant?/:color?/:quality?",
    meta: _91_91quality_93_93hOHeUd3myzMeta || {},
    component: () => import("/app/src/pages/buyout/[[type]]/[[manufacturer]]/[[meta_master]]/[[variant]]/[[color]]/[[quality]].vue").then(m => m.default || m)
  },
  {
    name: "change-password-uid-token___en",
    path: "/en/change-password/:uid()/:token()",
    meta: _91token_93ektCOnLsFyMeta || {},
    component: () => import("/app/src/pages/change-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: "change-password-uid-token___sk",
    path: "/zmenit-heslo/:uid()/:token()",
    meta: _91token_93ektCOnLsFyMeta || {},
    component: () => import("/app/src/pages/change-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: "change-password___en",
    path: "/en/change-password",
    meta: indexn8gFv0ycJqMeta || {},
    component: () => import("/app/src/pages/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "change-password___sk",
    path: "/zmenit-heslo",
    meta: indexn8gFv0ycJqMeta || {},
    component: () => import("/app/src/pages/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "commission-system___en",
    path: "/en/terms-and-conditions/commission-system",
    meta: commission_45systemqpTTmu12tfMeta || {},
    component: () => import("/app/src/pages/commission-system.vue").then(m => m.default || m)
  },
  {
    name: "commission-system___sk",
    path: "/obchodne-podmienky/provizny-system",
    meta: commission_45systemqpTTmu12tfMeta || {},
    component: () => import("/app/src/pages/commission-system.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/en/contact",
    meta: contactmqHVTjI8koMeta || {},
    component: () => import("/app/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___sk",
    path: "/kontakt",
    meta: contactmqHVTjI8koMeta || {},
    component: () => import("/app/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "cookies___en",
    path: "/en/privacy-policy/cookies",
    meta: cookiesljJJ99na9gMeta || {},
    component: () => import("/app/src/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: "cookies___sk",
    path: "/ochrana-osobnich-udaju/cookies",
    meta: cookiesljJJ99na9gMeta || {},
    component: () => import("/app/src/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: "dpd-map___en",
    path: "/en/dpd",
    meta: dpd_45mapCU2rs7HyqhMeta || {},
    component: () => import("/app/src/pages/dpd-map.vue").then(m => m.default || m)
  },
  {
    name: "dpd-map___sk",
    path: "/dpd",
    meta: dpd_45mapCU2rs7HyqhMeta || {},
    component: () => import("/app/src/pages/dpd-map.vue").then(m => m.default || m)
  },
  {
    name: "forgotten-password-uid-token___en",
    path: "/en/forgotten-password/:uid()/:token()",
    meta: _91token_93xFfebiZdaKMeta || {},
    component: () => import("/app/src/pages/forgotten-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: "forgotten-password-uid-token___sk",
    path: "/zabudnute-heslo/:uid()/:token()",
    meta: _91token_93xFfebiZdaKMeta || {},
    component: () => import("/app/src/pages/forgotten-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: "forgotten-password___en",
    path: "/en/forgotten-password",
    meta: index3sWtABJ4MtMeta || {},
    component: () => import("/app/src/pages/forgotten-password/index.vue").then(m => m.default || m)
  },
  {
    name: "forgotten-password___sk",
    path: "/zabudnute-heslo",
    meta: index3sWtABJ4MtMeta || {},
    component: () => import("/app/src/pages/forgotten-password/index.vue").then(m => m.default || m)
  },
  {
    name: "import-ambassadors___en",
    path: "/en/profile-premium/import-ambassadors",
    meta: import_45ambassadorspgVJXOWr3dMeta || {},
    component: () => import("/app/src/pages/import-ambassadors.vue").then(m => m.default || m)
  },
  {
    name: "import-ambassadors___sk",
    path: "/profil-premium/pridanie-ambasadoru",
    meta: import_45ambassadorspgVJXOWr3dMeta || {},
    component: () => import("/app/src/pages/import-ambassadors.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___sk",
    path: "/",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invite-action-id___en",
    path: "/en/invite-action/:id()",
    meta: _91id_93MoWQUnXsdLMeta || {},
    component: () => import("/app/src/pages/invite-action/[id].vue").then(m => m.default || m)
  },
  {
    name: "invite-action-id___sk",
    path: "/akcia-pozvanky/:id()",
    meta: _91id_93MoWQUnXsdLMeta || {},
    component: () => import("/app/src/pages/invite-action/[id].vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: login3hUYZUGxmUMeta || {},
    component: () => import("/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___sk",
    path: "/prihlasenie",
    meta: login3hUYZUGxmUMeta || {},
    component: () => import("/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "paper-contract-sign-id___en",
    path: "/en/paper-contract-sign/:id()",
    meta: _91id_93jtkvIme7zdMeta || {},
    component: () => import("/app/src/pages/paper-contract-sign/[id].vue").then(m => m.default || m)
  },
  {
    name: "paper-contract-sign-id___sk",
    path: "/papierove-podpisanie-zmluvy/:id()",
    meta: _91id_93jtkvIme7zdMeta || {},
    component: () => import("/app/src/pages/paper-contract-sign/[id].vue").then(m => m.default || m)
  },
  {
    name: "payout-instruction___en",
    path: "/en/payout-instruction",
    component: () => import("/app/src/pages/payout-instruction.vue").then(m => m.default || m)
  },
  {
    name: "payout-instruction___sk",
    path: "/pokyn-na-platbu",
    component: () => import("/app/src/pages/payout-instruction.vue").then(m => m.default || m)
  },
  {
    name: "premium___en",
    path: "/en/premium-partner",
    meta: premiumBHgECUpnrUMeta || {},
    component: () => import("/app/src/pages/premium.vue").then(m => m.default || m)
  },
  {
    name: "premium___sk",
    path: "/premium-partner",
    meta: premiumBHgECUpnrUMeta || {},
    component: () => import("/app/src/pages/premium.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    meta: privacy_45policyjUOcienEDCMeta || {},
    component: () => import("/app/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___sk",
    path: "/ochrana-osobnych-udajov",
    meta: privacy_45policyjUOcienEDCMeta || {},
    component: () => import("/app/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: profile_45premium200sa3HzYZMeta?.name,
    path: "/en/profile-premium",
    meta: profile_45premium200sa3HzYZMeta || {},
    component: () => import("/app/src/pages/profile-premium.vue").then(m => m.default || m),
    children: [
  {
    name: "profile-premium-ambassadors___en",
    path: "ambassadors",
    component: () => import("/app/src/pages/profile-premium/ambassadors.vue").then(m => m.default || m)
  },
  {
    name: "profile-premium-api___en",
    path: "api",
    component: () => import("/app/src/pages/profile-premium/api.vue").then(m => m.default || m)
  },
  {
    name: "profile-premium-company-data___en",
    path: "company-data",
    meta: company_45datayn4DbaCakSMeta || {},
    component: () => import("/app/src/pages/profile-premium/company-data.vue").then(m => m.default || m)
  },
  {
    name: "profile-premium-dashboard___en",
    path: "dashboard",
    component: () => import("/app/src/pages/profile-premium/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "profile-premium-education___en",
    path: "education",
    component: () => import("/app/src/pages/profile-premium/education.vue").then(m => m.default || m)
  },
  {
    name: "profile-premium-exports___en",
    path: "exports",
    component: () => import("/app/src/pages/profile-premium/exports.vue").then(m => m.default || m)
  },
  {
    name: "profile-premium___en",
    path: "",
    meta: indexhcifUt38GMMeta || {},
    component: () => import("/app/src/pages/profile-premium/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-premium-notifications___en",
    path: "notification",
    component: () => import("/app/src/pages/profile-premium/notifications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profile_45premium200sa3HzYZMeta?.name,
    path: "/profil-premium",
    meta: profile_45premium200sa3HzYZMeta || {},
    component: () => import("/app/src/pages/profile-premium.vue").then(m => m.default || m),
    children: [
  {
    name: "profile-premium-ambassadors___sk",
    path: "ambasadori",
    component: () => import("/app/src/pages/profile-premium/ambassadors.vue").then(m => m.default || m)
  },
  {
    name: "profile-premium-api___sk",
    path: "api",
    component: () => import("/app/src/pages/profile-premium/api.vue").then(m => m.default || m)
  },
  {
    name: "profile-premium-company-data___sk",
    path: "firemne-udaje",
    meta: company_45datayn4DbaCakSMeta || {},
    component: () => import("/app/src/pages/profile-premium/company-data.vue").then(m => m.default || m)
  },
  {
    name: "profile-premium-dashboard___sk",
    path: "prehled",
    component: () => import("/app/src/pages/profile-premium/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "profile-premium-education___sk",
    path: "vzdelanie",
    component: () => import("/app/src/pages/profile-premium/education.vue").then(m => m.default || m)
  },
  {
    name: "profile-premium-exports___sk",
    path: "exporty",
    component: () => import("/app/src/pages/profile-premium/exports.vue").then(m => m.default || m)
  },
  {
    name: "profile-premium___sk",
    path: "",
    meta: indexhcifUt38GMMeta || {},
    component: () => import("/app/src/pages/profile-premium/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-premium-notifications___sk",
    path: "upozornenie",
    component: () => import("/app/src/pages/profile-premium/notifications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileTx35vCKuH1Meta?.name,
    path: "/en/profile",
    meta: profileTx35vCKuH1Meta || {},
    component: () => import("/app/src/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "profile-bonuses___en",
    path: "bonuses",
    meta: bonusesNwXnstYbQHMeta || {},
    component: () => import("/app/src/pages/profile/bonuses.vue").then(m => m.default || m)
  },
  {
    name: "profile-dashboard___en",
    path: "dashboard",
    component: () => import("/app/src/pages/profile/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "profile-education___en",
    path: "education",
    component: () => import("/app/src/pages/profile/education.vue").then(m => m.default || m)
  },
  {
    name: "profile___en",
    path: "",
    meta: indexSJNeMkG0qzMeta || {},
    component: () => import("/app/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-messages___en",
    path: "messages",
    component: () => import("/app/src/pages/profile/messages.vue").then(m => m.default || m)
  },
  {
    name: "profile-my-buyouts___en",
    path: "buyouts",
    meta: my_45buyoutsnAdRS7UEZiMeta || {},
    component: () => import("/app/src/pages/profile/my-buyouts.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications___en",
    path: "notifications",
    component: () => import("/app/src/pages/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "profile-personal-info___en",
    path: "personal-info",
    meta: personal_45infoV0X93sJxn0Meta || {},
    component: () => import("/app/src/pages/profile/personal-info.vue").then(m => m.default || m)
  },
  {
    name: "profile-rewards___en",
    path: "rewards",
    component: () => import("/app/src/pages/profile/rewards.vue").then(m => m.default || m)
  },
  {
    name: "profile-tariff-list___en",
    path: "tariff-list",
    meta: tariff_45listBPA80H7A3hMeta || {},
    component: () => import("/app/src/pages/profile/tariff-list.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileTx35vCKuH1Meta?.name,
    path: "/profil",
    meta: profileTx35vCKuH1Meta || {},
    component: () => import("/app/src/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "profile-bonuses___sk",
    path: "bonusy",
    meta: bonusesNwXnstYbQHMeta || {},
    component: () => import("/app/src/pages/profile/bonuses.vue").then(m => m.default || m)
  },
  {
    name: "profile-dashboard___sk",
    path: "prehlad",
    component: () => import("/app/src/pages/profile/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "profile-education___sk",
    path: "vzdelanie",
    component: () => import("/app/src/pages/profile/education.vue").then(m => m.default || m)
  },
  {
    name: "profile___sk",
    path: "",
    meta: indexSJNeMkG0qzMeta || {},
    component: () => import("/app/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-messages___sk",
    path: "spravy",
    component: () => import("/app/src/pages/profile/messages.vue").then(m => m.default || m)
  },
  {
    name: "profile-my-buyouts___sk",
    path: "vykupy",
    meta: my_45buyoutsnAdRS7UEZiMeta || {},
    component: () => import("/app/src/pages/profile/my-buyouts.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications___sk",
    path: "upozornenie",
    component: () => import("/app/src/pages/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "profile-personal-info___sk",
    path: "osobne-udaje",
    meta: personal_45infoV0X93sJxn0Meta || {},
    component: () => import("/app/src/pages/profile/personal-info.vue").then(m => m.default || m)
  },
  {
    name: "profile-rewards___sk",
    path: "odmeny",
    component: () => import("/app/src/pages/profile/rewards.vue").then(m => m.default || m)
  },
  {
    name: "profile-tariff-list___sk",
    path: "sadzobnik",
    meta: tariff_45listBPA80H7A3hMeta || {},
    component: () => import("/app/src/pages/profile/tariff-list.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "questions___en",
    path: "/en/questions",
    meta: questions3T0iefRra1Meta || {},
    component: () => import("/app/src/pages/questions.vue").then(m => m.default || m)
  },
  {
    name: "questions___sk",
    path: "/otazky",
    meta: questions3T0iefRra1Meta || {},
    component: () => import("/app/src/pages/questions.vue").then(m => m.default || m)
  },
  {
    name: "register-ambassador___en",
    path: "/en/register/ambassador",
    meta: ambassadorfAMyKOcGo8Meta || {},
    component: () => import("/app/src/pages/register/ambassador.vue").then(m => m.default || m)
  },
  {
    name: "register-ambassador___sk",
    path: "/registracie/ambasador",
    meta: ambassadorfAMyKOcGo8Meta || {},
    component: () => import("/app/src/pages/register/ambassador.vue").then(m => m.default || m)
  },
  {
    name: "register-partner___en",
    path: "/en/register/partner",
    meta: partnerf6O1AltUwjMeta || {},
    component: () => import("/app/src/pages/register/partner.vue").then(m => m.default || m)
  },
  {
    name: "register-partner___sk",
    path: "/registracie/partner",
    meta: partnerf6O1AltUwjMeta || {},
    component: () => import("/app/src/pages/register/partner.vue").then(m => m.default || m)
  },
  {
    name: "set-new-password-uid-token___en",
    path: "/en/set-new-password/:uid()/:token()",
    meta: _91token_93Y0kYH2hlebMeta || {},
    component: () => import("/app/src/pages/set-new-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: "set-new-password-uid-token___sk",
    path: "/nastaveni-noveho-hesla/:uid()/:token()",
    meta: _91token_93Y0kYH2hlebMeta || {},
    component: () => import("/app/src/pages/set-new-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: "sms-contract-sign-id___en",
    path: "/en/sms-contract-sign/:id()",
    meta: _91id_93bW8M5cw9eRMeta || {},
    component: () => import("/app/src/pages/sms-contract-sign/[id].vue").then(m => m.default || m)
  },
  {
    name: "sms-contract-sign-id___sk",
    path: "/sms-podpisanie-zmluvy/:id()",
    meta: _91id_93bW8M5cw9eRMeta || {},
    component: () => import("/app/src/pages/sms-contract-sign/[id].vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en",
    path: "/en/terms-and-conditions",
    meta: terms_45and_45conditionsg5tk8tJRWzMeta || {},
    component: () => import("/app/src/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___sk",
    path: "/obchodne-podmienky",
    meta: terms_45and_45conditionsg5tk8tJRWzMeta || {},
    component: () => import("/app/src/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "vodafone-sso-finish",
    path: "/vodafone/sso-finish",
    meta: sso_45finishktFnF3flDKMeta || {},
    component: () => import("/app/src/pages/vodafone/sso-finish.vue").then(m => m.default || m)
  },
  {
    name: "vodafone-sso",
    path: "/vodafone/sso",
    meta: ssoVB0kdzIVIqMeta || {},
    component: () => import("/app/src/pages/vodafone/sso.vue").then(m => m.default || m)
  }
]